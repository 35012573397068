import React from 'react';
import './style.css';
import Footer from './footer';

const Company = () => {
  return (
    <>
    
    <section class="about">
    <h1>About Adept Technology Morocco</h1>
    
    <div class="about-info">
        <div class="about-img">
            <img src="/Adept Logo.png" alt=""/>
        </div>
        <div>
        <p>Adept Technology Morocco(ATM),Created in 2018,In Casablanca City of Morocco</p><br></br>
        <p>ATM,since it's creation,it's a pionner in the activities of electricity, automation and Robotics.</p><br></br>
        <p>Our know-how and our skills are based on a design office, our skills field and experienced and motivated staff</p><br></br>
        <p>whatever your project,the ATM company accompanies you,identifies allyour needs and offers you a customized installation that meets your expectations.</p>
         </div>
   </div>
</section>
 <div><Footer/></div>

 </>

  );
};

export default Company;