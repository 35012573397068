import React from 'react';
import './style.css';

const Footer = () => {
  return (
    <div className="mt-5 pt-5 pb-5 footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-xs-12 about-company">
            <h2>Adept Technology Morocco</h2>
            <p className="pr-5 text-white-50">your right partner for the future.</p>
            <p><a href="https://linkedin.com/company/adept-technology20/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin fa-lg mr-3" style={{ fontSize: '40px', marginRight: '10px' }}></i></a>
            <a href="https://wa.me/212661417094" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-whatsapp-square fa-lg"style={{ fontSize: '40px' }}></i></a></p>
          </div><br></br>
          <div className="col-lg-3 col-xs-12 links">
            <h4 className="mt-lg-0 mt-sm-3">Products</h4>
            <ul className="m-0 p-0">
              <li>Mobile Robots</li>
              <li>Industrial Robots</li>
              <li>Plc</li>
              <li>Drives</li>
                                                                                                              
            </ul><br></br>
          </div>
          <div className="col-lg-4 col-xs-12 location">
          <h4 className="mt-lg-0 mt-sm-4">Location</h4>
            <p>Casablanca</p><br></br>
            <p className="mb-0"><i className="fa fa-phone mr-3"></i>+212 661-417094</p>
            <p><i className="fa fa-envelope-o mr-3"></i>sales@Adept-technology.ma</p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col copyright">
            <p className=""><small className="text-white-50">© 2023. All Rights Reserved.</small></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
