import React from 'react';
import './style.css';
import { BiSearch, BiWrench, BiCreditCardFront,BiCodeAlt,BiCog,BiPhone } from 'react-icons/bi';
import Footer from './footer';
//import { FiSettings } from 'react-icons/fi';


const Services = () => {
  return (
    <>
    <section className="py-5 py-xl-8" >
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
            <h2 className="mb-4 display-5 text-center">Services</h2>
            <p className="text-secondary mb-5 text-center">We Offer Integrated Automation Solutions: Engineering Excellence from Concept to Optimization.</p>
            <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
          </div>
        </div>
      </div>

      <div className="container overflow-hidden">
        <div className="row gy-5 gx-md-5 justify-content-center">
          <div className="col-10 col-md-5 col-xl-4 overflow-hidden">
            <div className="row gy-4">
              <div className="col-12 col-lg-2">
                <BiSearch size={42} color="currentColor" style={{marginLeft:'15px'}}/>
              </div>
              <div className="col-12 col-lg-10">
                <h4 className="mb-3">Project study and design</h4>
                <p className="mb-3 text-secondary">Trust our experts to conduct thorough project studies, including feasibility assessments and detailed designs. We bring precision and innovation to every project, ensuring a solid foundation for successful execution..</p>
                <div></div>
              </div>
            </div>
          </div>

          <div className="col-10 col-md-5 col-xl-4 overflow-hidden">
            <div className="row gy-4">
              <div className="col-12 col-lg-2">
                <BiCodeAlt size={42} color="currentColor" />
              </div>
              <div className="col-12 col-lg-10">
                <h4 className="mb-3" >Engineering</h4>
                <p className="mb-3 text-secondary">Specializing in PLC, drives, robotics (both mobile and industrial), and electrical solutions, our engineering team delivers cutting-edge solutions. From concept to implementation, we optimize systems for efficiency and performance..</p>
                <div></div>
              </div>
            </div>
          </div>

          <div className="col-10 col-md-5 col-xl-4 overflow-hidden">
            <div className="row gy-4">
              <div className="col-12 col-lg-2">
                <BiCreditCardFront size={42} color="currentColor" />
              </div>
              <div className="col-12 col-lg-10">
                <h4 className="mb-3">Erection & commissioning</h4>
                <p className="mb-3 text-secondary">Seamlessly transition from project setup to full-scale operation. Our experienced professionals handle the meticulous erection of structures, installations, and commissioning of automation systems, guaranteeing peak performance..</p>
                <div></div>
              </div>
            </div>
          </div>

          
          <div className="col-10 col-md-5 col-xl-4 overflow-hidden">
            <div className="row gy-4">
              <div className="col-12 col-lg-2">
                <BiWrench size={42} color="currentColor" style={{marginLeft:'15px'}} />
              </div>
              <div className="col-12 col-lg-10">
                <h4 className="mb-3">Improuvement of existing facilities</h4>
                <p className="mb-3 text-secondary">Optimize your current infrastructure with our improvement services. Whether it's upgrading PLC systems, enhancing drives, or refining robotics solutions, we tailor improvements to elevate the efficiency of your facilities..</p>
                <div></div>
              </div>
            </div>
          </div>


          <div className="col-10 col-md-5 col-xl-4 overflow-hidden">
            <div className="row gy-4">
              <div className="col-12 col-lg-2">
                <BiCog size={42} color="currentColor"  />
              </div>
              <div className="col-12 col-lg-10">
                <h4 className="mb-3">Troubleshooting</h4>
                <p className="mb-3 text-secondary">Count on us for quick and effective troubleshooting services. Our dedicated team identifies and resolves issues efficiently, minimizing downtime and ensuring the continuous, reliable operation of your systems..</p>
                <div></div>
              </div>
            </div>
          </div>

          <div className="col-10 col-md-5 col-xl-4 overflow-hidden">
            <div className="row gy-4">
              <div className="col-12 col-lg-2">
                <BiPhone size={42} color="currentColor" />
              </div>
              <div className="col-12 col-lg-10">
                <h4 className="mb-3">24/7 Support</h4>
                <p className="mb-3 text-secondary">We offer 24/7 support to our clients. This means that you can always get help when you need it, no matter what time of day or night it is.</p>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
     <div>
        <Footer />
     </div>

    </section>
    </>
  );
};

export default Services;
