// Contact.js
import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './footer';

const Contact = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    company: '',
    country: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('http://localhost:3001/api/form-submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setFormData({
          fullName: '',
          email: '',
          company: '',
          country: '',
          message: '',
        });
        toast.success('Form submitted successfully!');
      } else {
        toast.error('Error submitting form. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Error submitting form. Please try again.');
    }
  };

  return (
    <>
      <section style={{marginTop:'170px'}}>
        <div className="container mt-5">
          <div className="card p-4 shadow" style={{width:'100'}}>
            <h2 className="mb-4" style={{fontFamily:'Arial, Helvetica, sans-serif'}}>Contact Adept Technology Morocco</h2>
            <p>
              All fields are required<br />
              Please fill out the form below or call us toll-free at{' '}
              <span style={{ color: 'red' }}>+212 666-417094</span>
            </p>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="fullName" className="form-label">
                  Full Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fullName"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Business Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="company" className="form-label">
                  Company
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="company"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="country" className="form-label">
                  Country
                </label>
                <select
                  className="form-select"
                  id="country"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  required
                >
              
  <option value="Morocco">Morocco</option>
  <option value="Tunis">Tunis</option>
  <option value="Algeria">Algeria</option>
  <option value="United States">United States</option>
  <option value="Germany">Germany</option>
<option value="Switzerland">Switzerland</option>
  <option value="Sweden">Nigeria</option>
  <option value="Netherlands">Netherlands</option>
  <option value="United Kingdom">United Kingdom</option>
  <option value="Belgium">Belgium</option>
  <option value="France">France</option>
  <option value="Italy">Italy</option>
  <option value="Spain">Spain</option>
  <option value="South Africa">South Africa</option>
</select>

               
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label">
                  Message
                </label>
                <textarea
                  className="form-control"
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
          </div>
        </div>
      </section>

      <section>
        <div>
          <ToastContainer />
          <Footer />
        </div>
      </section>
    </>
  );
};

export default Contact;
