import React, { useState } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { Navbar as BootstrapNavbar, Nav } from 'react-bootstrap';
import './style.css';

const Navbar = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <BootstrapNavbar
        expanded={expanded}
        expand="lg"
        variant="dark" // Set variant to dark for a dark navbar
        fixed="top"
        className="custom-navbar" // Added custom-navbar class
      >
        <Link to="/" className="navbar-brand">
          <img src="/Adept Logo.png" height="50" width="60" alt="Logo" className="navbar-logo" />
        </Link>
        <BootstrapNavbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => setExpanded(!expanded)}
          className="custom-toggler-icon"
        />
        <BootstrapNavbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <Link to="/" className="nav-link" onClick={() => setExpanded(false)}>
              Home
            </Link>
            <Link to="/products" className="nav-link" onClick={() => setExpanded(false)}>
              Products
            </Link>
            <Link to="/services" className="nav-link" onClick={() => setExpanded(false)}>
              Services
            </Link>
            <Link to="/company" className="nav-link" onClick={() => setExpanded(false)}>
              Company
            </Link>
            <Link to="/contact" className="nav-link" onClick={() => setExpanded(false)}>
              Contact
            </Link>
          </Nav>
        </BootstrapNavbar.Collapse>
      </BootstrapNavbar>
      <Outlet />
    </>
  );
};

export default Navbar;
