// App.js
import React, { useState } from 'react';
import ProductList from './ProductList';
import SearchBar from './SearchBar';
import CategorySelector from './CategorySelector';

const Products = () => {
  // Sample product data
  const products = [

    { id: 1, name: 'AMR SJV-SW500', category: 'Mobile Robots', image: '/images/AMR-SJV-SW500.png', description:'Robots SJV-SW500, equipped with SRC series core controller, has an ultra-low chassis, so that it can drill into the shelf for jacking and transfer with a limit load of 500kg. This AMR transport tracking robot has various navigation methods and flexible deployment. Its slim body enables to move in all directions easily.'},
     { id: 2, name: 'AMB-800K', category: 'Mobile Robots', image: '/images/AMB-800K.png', description: 'The AMB-800K rotary jacking robot is equipped with SRC-800 series controller. It has an ultra-low chassis and can drill into the rack for jacking and handling. The load is up to 800kg, and it can control the rotation of the robot body and the steering of lifting goods. It supports pure laser SLAM navigation.', },
     { id: 3, name: 'AMB-300JZ', category: 'Mobile Robots', image: '/images/AMB-300JZ.png', description: ' The AMB-300JZ is an upgraded version of the AMB-300J robot, adopting SEERs new PI industrial design. It closely matches the size of the AMB-150J with a 300 kg load capacity. New features include an optional QR code camera and 5G module, supporting small-range reflector navigation. The robot retains its ability to identify racks and lift them using the jack-up mechanism, with a reduced chassis height.' },
      { id: 4, name: 'AMB-JS', category: 'Mobile Robots', image: '/images/AMB-JS.png', description: 'The AMB-JS Safety Jacking Car is a laser SLAM handling robot with a 500KG or 1000KG load capacity. It accurately identifies material racks and safely lifts goods, equipped with SRC-3000FS safety controller complying with CE MD safety standards. The SEER industrial design enhances its industrial character with a stylish metal texture..      '},
      { id: 5, name: 'SJV-W600DS-DL', category: 'Mobile Robots', image: '/images/SJV-W600DS-DL.png', description: 'Laser Slam Omni-directional AGV Robotics SJV-W600DS-DL, equipped with SRC series core controller, has an ultra-low chassis, so that it can drill into the shelf for jacking and transfer with a limit load of 600kg. This laser-guided AGV has various navigation methods and flexible deployment. Its slim body enables to move in all directions easily.      '},
      { id: 6, name: 'SJV-SW600', category: 'Mobile Robots', image: '/images/SJV-SW600.png', description: 'The SJV-SW600 rotary jacking robot, powered by an SRC-800 controller, lifts up to 600kg. It smoothly maneuvers under shelves using dual lasers for laser SLAM navigation or a hybrid option with QR code/reflector support. With its adaptable lifting and spinning functions, the SJV-SW600 excels in tasks like e-commerce sorting, material handling, and call feeding, offering efficiency in autonomous transportation.'},
      { id: 7, name: 'SFL-CDD14', category: 'Mobile Robots', image: '/images/SFL-CDD14.png', description: ' The SFL-CDD14 Smart Forklift, equipped with a built-in SRC Series Controller, utilizes Laser SLAM navigation for easy deployment without reflectors. It accurately handles pallets with a pallet identification sensor, maneuvers efficiently in narrow aisles, and ensures 3D safety protection with obstacle avoidance lasers and safety bumpers. A preferred choice for goods transfer, stacking, and palletizing in factory environments..'},
      { id: 8, name: 'PADBOT W2', category: 'Mobile Robots', image: '/images/Serv-robot.png', description: 'PadBot W2 is a delivery robot that serves to deliver parcels or documents to your designated destination. The auto-navigation system ensures safe delivery, saving on manpower and materials. The inbuilt smart notification and QR code security allows for a more secured collection.'}

    // ... add more products
  ];

  const [filteredProducts, setFilteredProducts] = useState(products);
  const [selectedCategory, setSelectedCategory] = useState('');

  const handleSearch = (searchTerm) => {
    const filtered = products.filter(product =>
      product.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProducts(filtered);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    // Filter products based on category
    const filtered = category ? products.filter(product => product.category === category) : products;
    setFilteredProducts(filtered);
  };

  return (
    <div className="container">
      <SearchBar onSearch={handleSearch} />
      <CategorySelector
        categories={['Mobile Robots', 'Industrial Robots', 'Plc', 'Drives']}
        selectedCategory={selectedCategory}
        onSelectCategory={handleCategoryChange}
      />
      <ProductList products={filteredProducts} />
    </div>
  );
};

export default Products;
