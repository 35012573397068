 // src/components/Home.js
 import React from 'react';
 import Footer from './footer';
 import './style.css'
 
 
 
 const Home = () => {
  
   
   return (
     <>
       {/* Your Home component JSX goes here */}
       <section className="mb-5">
   <div className="bg bg-image"  style={{ maxWidth: '100%'}}>
     <img
       src="/images/Home-Background.jpg"
       className="w-100"
       alt="City Background"/>
    <h1
    className="adeptTitle text-light position-absolute ms-3 mb-0">
       Adept technology Morocco your right partner for the future.
     </h1>
   </div>
 </section>
 
 
 
       <section className="mt-5">
       <div className="Robotic d-flex justify-content-center align-items-center flex-column">
      <h1>                                 
       Elevate Your Business: Expert Integration of Advanced Robotics Solutions.
      </h1>
   </div>
         <div className="container mt-2">
           <div className="row">
             <div className="col-md-3 col-sm-6">   
               <div className="card card-block">
                 <img src="/images/AMR-SJV-SW500.png" height="220px" width="100%" alt="Sierra Web Development Team" className='img-act' />
                 <h5 className="card-title mt-3 mb-3" style={{fontWeight:'Bold',marginLeft:'10px',fontSize:'1.2rem'}}>Autonomous Mobile Robot  (AMR)</h5>
                 <p className="card-text" style={{fontWeight:'Bold',marginLeft:'10px'}}>Take your warehouse operations to the next level with Adept Technology Morocco Autonomous Mobile Robots (AMRs). Our solution is meticulously crafted for seamless integration into warehouse environments, offering a transformative approach to material handling and logistics.</p>
               </div>
             </div>
             <div className="col-md-3 col-sm-6">
               <div className="card card-block">
                 <img src="/images/AMB-300JZ.png" height="220px" width="100%" alt="ProVyuh Team" className='img-act'/>
                 <h5 className="card-title  mt-3 mb-3"   style={{fontWeight:'Bold',marginLeft:'10px',fontSize:'1.2rem'}}>Automated guided vehicle (AGV)</h5>
                 <p className="card-text"style={{fontWeight:'Bold',marginLeft:'10px'}}>Elevate your material handling operations with Adept Technology Morocco Automated Guided Vehicles (AGVs). Meticulously engineered for precision and efficiency, our AGVs redefine the logistics landscape, seamlessly automating material transport with unmatched reliability.</p>
               </div>
             </div>
             <div className="col-md-3 col-sm-6">
               <div className="card card-block">
                 <img src="/images/SFL-CDD14.png" height="220px" width="100%" alt="ProVyuh Team" className='img-act' />
                 <h5 className="card-title  mt-3 mb-3"  style={{fontWeight:'Bold',marginLeft:'10px',fontSize:'1.2rem'}}>Autonomous forklift</h5>
                 <p className="card-text"style={{fontWeight:'Bold',marginLeft:'10px'}}>Step into a new era of warehouse efficiency with Adept Technology Morocco Autonomous Forklift. Engineered with cutting-edge technology, our forklifts redefine material handling by combining precision, adaptability, and safety in a single intelligent solution..</p>
               </div>
             </div>
             <div className="col-md-3 col-sm-6">
               <div className="card card-block">
                 <img src="/images/cleanning-robot.png" height="220px" width="80%" alt="ProVyuh Team" className='img-act' />
                 <h5 className="card-title  mt-3 mb-3"  style={{fontWeight:'Bold',marginLeft:'10px',fontSize:'1.2rem'}}>Cleaning robots</h5>
                 <p className="card-text"style={{fontWeight:'Bold',marginLeft:'10px'}}>Welcome to a new era of smart cleaning solutions with Adept Technology Morocco Cleaning Robot. Engineered for efficiency and precision, our robotic cleaning assistant revolutionizes the way you maintain a pristine environment with ease.</p>
               </div>
             </div>
             <div className="col-md-3 col-sm-6">
               <div className="card card-block">
                 <img src="/images/Serv-robot.png" height="220px" width="100%" alt="ProVyuh Team" className='img-act' />
                 <h5 className="card-title  mt-3 mb-3"   style={{fontWeight:'Bold',marginLeft:'10px',fontSize:'1.2rem'}}>Serving robots</h5>
                 <p className="card-text"style={{fontWeight:'Bold',marginLeft:'10px'}}>Step into a new era of service sophistication as Adept Technology's Serving Robot takes center stage in your restaurant and hotel. Custom-crafted for the unique demands of hospitality, this robotic serving assistant redefines the dining and guest experience by seamlessly delivering exceptional service with a touch of futuristic elegance.</p>
               </div>
             </div>
             <div className="col-md-3 col-sm-6">
               <div className="card card-block">
                 <img src="/images/Industrial_robot-home.png" height="220px" width="100%" alt="ProVyuh Team" className='img-act' />
                 <h5 className="card-title  mt-3 mb-3" style={{fontWeight:'Bold',marginLeft:'10px',fontSize:'1.2rem'}}>Industrial robots</h5>
                 <p className="card-text"style={{fontWeight:'Bold',marginLeft:'10px'}}>Enter the realm of unparalleled industrial automation with Adept Technology Morocco Industrial Robots. Engineered for precision, adaptability, and efficiency,robotic solutions redefine manufacturing and industrial processes across diverse sectors.</p>
               </div>
             </div>
            </div>
         </div>
       </section>
 
       <section id="gallery" style={{ marginTop: '200px',backgroundColor:'#d3d3d3', background: 'linear-gradient(150eg, rgb(255, 245, 245) 0%, rgb(144, 148, 144) 80%)'}}>
   <div className="services container">
   <h1>
   "Empowering Efficiency: Adept Technology Morocco's Advanced PLC Drive Solutions and Electrical Services"
 </h1>
     <div className="row">
     <div className="col-lg-4 mb-4">
     <div className="card services-card">
       <img  src="/images/abb-plc.jpg" alt="" class="card-img-top" />
       <div className="card-body">
         <h5 className="card-title"  style={{fontWeight:'Bold',fontSize:'1.2rem'}}>Automate programmable industriel solutions</h5>
         <p className="card-text">
        Adept technology Morocco excels in seamless integration solutions and advanced PLC services. With a focus on precision and efficiency, we design, implement, and maintain tailored PLC systems. Our commitment to innovation empowers businesses to thrive in industrial automation.</p>
       </div>
      </div>
     </div>
   <div className="col-lg-4 mb-4">
   <div className="card  services-card">
       <img  src="/images/Drives-abb.png" alt="" class="card-img-top" />
       <div className="card-body">
         <h5 className="card-title"  style={{fontWeight:'Bold',fontSize:'1.2rem'}}>Drives</h5>
         <p className="card-text">
       Adept technology Morocco excels in integrating cutting-edge drive technologies into diverse industrial settings, offering comprehensive services from design to ongoing support. Specializing in variable frequency drives (VFDs), servo drives, and advanced drive systems, our skilled team ensures optimal performance and efficiency. Committed to staying current with industry trends, we stand as a reliable partner, empowering businesses to navigate drive integration complexities and capitalize on enhanced control and precision.</p>
       </div>
       </div>
     </div>
     <div className="col-lg-4 mb-4">
     <div className="card  services-card">
       <img  src="/images/armoires-electriques.jpg" alt="" class="card-img-top"/>
       <div className="card-body">
         <h5 className="card-title"  style={{fontWeight:'Bold',fontSize:'1.2rem'}}>Electrical Engineering and installations </h5>
         <p className="card-text">Adept technology Morocco excels in seamlessly Engineering and installation electrical solutions, offering customized solutions for diverse industrial and commercial needs. From design to ongoing maintenance, our dedicated team ensures precision and efficiency in every project, prioritizing innovation and reliability. As a trusted partner, we empower businesses with robust solutions for enhanced functionality and performance in electrical integration.</p>
       </div>
      </div>
     </div>
   </div>
 </div>
 </section>
 

<section>
<div className='scroller'>
<h1>Trusted by industry leaders</h1>
<div className="wrapper">
  <div className="scrolling-container">
  <div className="item"><img src='/images/TE_Connectivity.png' alt='' /></div>
  <div className="item"><img src='/images/RMC.jpeg' alt='' /></div>
  <div className="item"><img src='/images/SEBN-MA.jpg' alt='' /></div>
  <div className="item"><img src='/images/DANONE.jpg' alt='' /></div>
</div>
</div>
</div>
</section>

<section>
<div>
      {/* Your home component content goes here */}
      {/* Include the Footer component at the bottom of your home component */}
      <Footer />                                                                                                                                                                                                                                       
    </div>
</section>

</>
  );
};

export default Home;
