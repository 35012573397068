// ProductList.js
import React from 'react';
import './style.css';


const ProductList = ({ products }) => {
  return (
    <>
    <section style={{marginTop:'100px'}}>
    <div className="row">
      {products.map(product => (
        <div key={product.id} className="col-lg-3 col-md-4 col-sm-6 mb-4">
          <div className="card custom-card">
            <img
              src={process.env.PUBLIC_URL + product.image}
              className="card-img-top custom-image" // Add custom class for styling
              alt={product.name}
            />
            <div className="card-body">
              <h5 className="card-title" style={{fontWeight:'Bold',fontSize:'1.2rem'}}>{product.name}</h5>
              <p className="card-text">{product.description}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
    </section>





    </>
  );
};

export default ProductList;

